/*======================
    404 page
=======================*/

.page_404 {
  padding: 40px 0;
  background: #fff;
}
.page_404 p {
  font-size: 14px;
  font-weight: 600;
}
.page_404 img {
  width: 100%;
}

.four_zero_four_bg {
  background-image: url("../../assets/images/errorsnack.gif");
  height: 400px;
  width: 85%;
  display: flex;
  justify-content: center;
  margin: auto;
  background-position: center;
}

.four_zero_four_bg h1 {
  font-size: 80px;
}

.four_zero_four_bg h3 {
  font-size: 80px;
}

.link_404 {
  color: #f3f4f5 !important;
  padding: 10px 20px;
  background: #39ac31;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404 {
  margin-top: -50px;
}
